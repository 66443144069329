// @flow
import { all, fork, put, takeEvery, call } from "redux-saga/effects";

import {
  login as loginApi,
  logout as logoutApi,
  forgotPassword as forgotPasswordApi,
  newPassword as newPasswordApi,
  verifyEmail as verifyEmailApi,
  invitationSignup as invitationSignupApi,
  signup as signupApi,
  forgotPasswordConfirm,
} from "../../helpers/";

import { APICore, setAuthorization } from "../../helpers/api/apiCore";
import { authApiResponseSuccess, authApiResponseError } from "./actions";
import { AuthActionTypes } from "./constants";

const api = new APICore();

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({ payload: { username, password } }) {
  try {
    const response = yield call(loginApi, { username, password });
    // const user = response.data;
    // const statusCode = response[0];
    // if (statusCode !== 200) {
    //   return;
    // }
    const user = response[1];

    // NOTE - You can change this according to response format from your api
    //api.setLoggedInUser(user
    api.setLoggedInUserInLocalStorage(user);
    setAuthorization(user["token"]);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGIN_USER, user));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGIN_USER, error[1]));
    //api.setLoggedInUser(null);
    api.setLoggedInUserInLocalStorage(null);
    setAuthorization(null);
  }
}

/**
 * Logout the user
 */
function* logout() {
  try {
    const response = yield call(logoutApi);
    console.log("logout response", response);
    api.setLoggedInUser(null);
    api.setLoggedInUserInLocalStorage(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.LOGOUT_USER, {}));
  } catch (error) {
    yield put(authApiResponseError(AuthActionTypes.LOGOUT_USER, error));
  }
}

function* signup({
  payload: { firstName, lastName, companyName, email, password },
}) {
  try {
    yield call(signupApi, {
      firstName,
      lastName,
      companyName,
      email,
      password,
    });
    api.setLoggedInUserInLocalStorage(null);
    setAuthorization(null);
    yield put(authApiResponseSuccess(AuthActionTypes.SIGNUP_USER, null));
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.SIGNUP_USER, error[1].message)
    );
    //api.setLoggedInUser(null);
    api.setLoggedInUserInLocalStorage(null);
    setAuthorization(null);
  }
}

function* invitationSignup({
  payload: { firstName, lastName, email, password, invitationId },
}) {
  try {
    yield call(invitationSignupApi, {
      firstName,
      lastName,
      email,
      password,
      invitationId,
    });
    api.setLoggedInUserInLocalStorage(null);
    setAuthorization(null);
    yield put(
      authApiResponseSuccess(AuthActionTypes.INVITATION_SIGNUP_USER, null)
    );
  } catch (error) {
    yield put(
      authApiResponseError(
        AuthActionTypes.INVITATION_SIGNUP_USER,
        error[1].message
      )
    );
    api.setLoggedInUserInLocalStorage(null);
    setAuthorization(null);
  }
}

function* forgotPassword({ payload: { username } }) {
  try {
    const response = yield call(forgotPasswordApi, { username });
    yield put(
      authApiResponseSuccess(AuthActionTypes.FORGOT_PASSWORD, response[1])
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.FORGOT_PASSWORD, error[1].message)
    );
  }
}

function* newPassword({ payload: { email, code, newPassword } }) {
  try {
    const response = yield call(newPasswordApi, {
      email,
      code,
      newPassword,
    });
    yield put(
      authApiResponseSuccess(AuthActionTypes.SET_NEW_PASSWORD, response[1])
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.SET_NEW_PASSWORD, error[1].message)
    );
  }
}

function* verifyEmail({ payload: { email, code } }) {
  try {
    const response = yield call(verifyEmailApi, {
      email,
      code,
    });
    yield put(
      authApiResponseSuccess(AuthActionTypes.VERIFY_EMAIL, response[1])
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.VERIFY_EMAIL, error[1].message)
    );
  }
}

function* forgotPasswordChange({ payload: { data } }) {
  try {
    const response = yield call(forgotPasswordConfirm, data);
    yield put(
      authApiResponseSuccess(
        AuthActionTypes.FORGOT_PASSWORD_CHANGE,
        response.data
      )
    );
  } catch (error) {
    yield put(
      authApiResponseError(AuthActionTypes.FORGOT_PASSWORD_CHANGE, error)
    );
  }
}

export function* watchLoginUser() {
  yield takeEvery(AuthActionTypes.LOGIN_USER, login);
}

export function* watchLogout() {
  yield takeEvery(AuthActionTypes.LOGOUT_USER, logout);
}

export function* watchSignup() {
  yield takeEvery(AuthActionTypes.SIGNUP_USER, signup);
}

export function* watchInvitationSignup() {
  yield takeEvery(AuthActionTypes.INVITATION_SIGNUP_USER, invitationSignup);
}

export function* watchForgotPassword() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD, forgotPassword);
}

export function* watchNewPassword() {
  yield takeEvery(AuthActionTypes.SET_NEW_PASSWORD, newPassword);
}

export function* watchForgotPasswordChange() {
  yield takeEvery(AuthActionTypes.FORGOT_PASSWORD_CHANGE, forgotPasswordChange);
}

export function* watchVerifyEmail() {
  yield takeEvery(AuthActionTypes.VERIFY_EMAIL, verifyEmail);
}

function* authSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchLogout),
    fork(watchSignup),
    fork(watchInvitationSignup),
    fork(watchNewPassword),
    fork(watchVerifyEmail),
    fork(watchForgotPassword),
    fork(watchForgotPasswordChange),
  ]);
}

export default authSaga;
