import { API } from "aws-amplify";

const env = () => {
  if (API.Credentials._config.aws_user_files_s3_bucket) {
    return API.Credentials._config.aws_user_files_s3_bucket.split("-")[1];
  } else if (window.location.href.includes("http://localhost:3000")) {
    return "devdd";
  } else {
    return "productive";
  }
};

export default env();
