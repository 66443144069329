export const getLoggedInUser = `
query MyQuery($id: ID!) {
  getUsers(id: $id) {
    id
    email
    country
    firstName
    language
    lastName
    role {
      permissions
      roleName
      type
      id
    }
    roleId
    companyId
    notifications
    productTourStatus
    company {
      name
      id
      contactPerson {
        createdAt
        email
        firstName
        id
        imageUrl
        lastName
        mobil
        phone
        position
        updatedAt
      }
    }
  }
}`;
