import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// Amplify
import { Storage } from "aws-amplify";

//context with default values
const ProfileImageContext = React.createContext({
  image: "",
  loading: true,
  download: () => {},
});

//Context provider, which can be used anywhere on the dashboard to get profile image or to initiate a download request of the profile image.
export const ProfileImageProvider = (props) => {
  const { user } = useSelector((state) => ({
    user: state.Auth.user,
  }));
  // let currentUser;
  // Auth.currentAuthenticatedUser().then((user) => {
  //   currentUser = user;
  // });
  //image url and loading states, can be used anywhere on the dashboard for redering the image or have an status of the download.
  const [image, setImage] = useState("");
  const [loading, setLoading] = useState(true);

  //to reset this component on user logout.
  useEffect(() => {
    setImage("");
    setLoading(true);
  }, [user]);

  useEffect(() => {
    const getContactPersonImage = async () => {
      return await Storage.get(`users/${user.id}/profile-image`, {
        download: true,
      }); // get key from Storage.list
    };
    //return when the user is null (not logged-in) or loading is false.
    if (!loading || !user) return;
    // const getUserPhoto = async (url) => {
    //   return await getDownloadURL(ref(STORAGE, url));
    // };

    if (user)
      getContactPersonImage()
        .then((url) => {
          const downloadedImage = URL.createObjectURL(url.Body);
          setImage(downloadedImage);
        })
        .catch((error) => {
          setImage("");
        })
        .finally(() => {
          setLoading(false);
        });
    else {
      setLoading(() => setLoading(false));
    }
  }, [user, loading]);

  const download = () => {
    //to initiate the download again.
    setLoading(true);
  };

  return (
    <ProfileImageContext.Provider
      value={{
        image,
        loading,
        download,
      }}
    >
      {props.children}
    </ProfileImageContext.Provider>
  );
};

export default ProfileImageContext;
