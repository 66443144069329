import React from "react";
import { useSelector } from "react-redux";
import PermissionContext from "./PermissionContext";
// This provider is intended to be surrounding the whole application.
// It should receive the users permissions as parameter
const PermissionProvider = ({ children }) => {
  //constants & variables
  
  const { user } = useSelector((state) => ({
    user: state.Auth.user,
  }));


  // Creates a method that returns whether the requested permission is available in the list of permissions
  // passed as parameter



  const isAllowedTo = (permission) => {if(user) {return user.permissions.includes(permission)} else return false};

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  return (
    <PermissionContext.Provider value={{ isAllowedTo }}>
      {children}
    </PermissionContext.Provider>
  );
};

export default PermissionProvider;
