/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://4h5fo5zu3rcanoozqyu4t3mhfy.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-id36hzwuwnhchaui55pjt7obs4",
    "aws_cognito_identity_pool_id": "eu-central-1:0161fb2e-1856-481a-8aa0-20cec721133a",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_K5ugSgefp",
    "aws_user_pools_web_client_id": "59nin32ha68qvhjq135rnbcaf",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "lineupdashboard91348-productive",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
