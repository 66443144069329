/* Layout types and other constants */

export const LAYOUT_VERTICAL = "vertical";
export const LAYOUT_HORIZONTAL = "topnav";
export const LAYOUT_DETACHED = "detached";
export const LAYOUT_FULL_SCREEN = "fullscreen";

export const LAYOUT_WIDTH_FLUID = "fluid";
export const LAYOUT_WIDTH_BOXED = "boxed";

export const LEFT_SIDEBAR_THEME_DEFAULT = "default";
export const LEFT_SIDEBAR_THEME_LIGHT = "light";
export const LEFT_SIDEBAR_THEME_DARK = "dark";

export const LEFT_SIDEBAR_TYPE_FIXED = "fixed";
export const LEFT_SIDEBAR_TYPE_CONDENSED = "condensed";
export const LEFT_SIDEBAR_TYPE_SCROLLABLE = "scrollable";
