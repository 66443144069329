// @flow
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";

// code splitting and lazy loading
// https://blog.logrocket.com/lazy-loading-components-in-react-16-6-6cea535c0b52

const loading = () => <div className=""></div>;

const FullScreenLayout = ({ children }, state) => {
  const dispatch = useDispatch();

  return (
    <>
      <div className="full-content-page">
        
          <Suspense fallback={loading()}>{children}</Suspense>
    
      </div>
    </>
  );
};
export default FullScreenLayout;
