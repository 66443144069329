// @flow
import React, { useEffect } from "react";
import Routes from "./routes/Routes";

// setup fake backend
import { configureFakeBackend } from "./helpers";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Themes
import { VERSION } from "./constants/version";
// For Saas import Saas.scss
import "./assets/scss/Saas.scss";
// For Dark import Saas-Dark.scss
// import './assets/scss/Saas-Dark.scss';

// For Modern demo import Modern.scss
// import './assets/scss/Modern.scss';
// For Modern dark demo import Modern-Dark.scss
// import './assets/scss/Modern-Dark.scss';

// For Creative demo import Creative.scss
// import './assets/scss/Creative.scss';
// For Creative dark demo import Creative-Dark.scss
// import './assets/scss/Creative-Dark.scss';

// configure fake backend
import PermissionProvider from "./helpers/permission/PermissionProvider.js";
import { ProfileImageProvider } from "./helpers/ProfileImageProvider.js";
import { useSelector, useDispatch } from "react-redux";

import { Auth } from "aws-amplify";

//actions
import { logoutUser } from "./redux/actions";

configureFakeBackend();

/**
 * Main app component
 */
const App = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => ({
    user: state.Auth.user,
  }));

  useEffect(() => {
    if (
      !window.location.href.includes("/logout") &&
      !window.location.href.includes("/login") &&
      !window.location.href.includes("/register") &&
      !window.location.href.includes("/forget-password") &&
      !window.location.href.includes("/confirm") &&
      !window.location.href.includes("/maintenance") &&
      !window.location.href.includes("/error-404") &&
      !window.location.href.includes("/error-500") &&
      !window.location.href.includes("/new-password") &&
      !window.location.href.includes("/lock-screen") &&
      !window.location.href.includes("/verify-email") &&
      VERSION !== user.version
    ) {
      window.location.replace("/logout");
    }
    if (
      (!user && !window.location.href.includes("http://localhost:3000")) ||
      (user &&
        user.role !== "developer" &&
        !window.location.href.includes("http://localhost:3000"))
    ) {
      // eslint-disable-next-line
      console = {};
      console.log = function () {};
      console.info = function () {};
      console.warn = function () {};
      console.error = function () {};
    }
  }, [user]);

  // Automatically logout user if token is expired or invalid

  const currentAuthenticatedUser = async () => {
    try {
      await Auth.currentAuthenticatedUser();
    } catch (err) {
      if (
        !window.location.href.includes("/logout") &&
        !window.location.href.includes("/login") &&
        !window.location.href.includes("/register") &&
        !window.location.href.includes("/forget-password") &&
        !window.location.href.includes("/confirm") &&
        !window.location.href.includes("/maintenance") &&
        !window.location.href.includes("/error-404") &&
        !window.location.href.includes("/error-500") &&
        !window.location.href.includes("/new-password") &&
        !window.location.href.includes("/lock-screen") &&
        !window.location.href.includes("/verify-email")
      ) {
        window.location.replace("/logout");
      }
    }
  };

  currentAuthenticatedUser();

  return (
    <PermissionProvider>
      <ProfileImageProvider>
        <ToastContainer />
        <Routes></Routes>
      </ProfileImageProvider>
    </PermissionProvider>
  );
};
export default App;
