export const AuthActionTypes = {
  API_RESPONSE_SUCCESS: "@@auth/API_RESPONSE_SUCCESS",
  API_RESPONSE_ERROR: "@@auth/API_RESPONSE_ERROR",
  LOGIN_USER: "@@auth/LOGIN_USER",
  LOGOUT_USER: "@@auth/LOGOUT_USER",
  SIGNUP_USER: "@@auth/SIGNUP_USER",
  INVITATION_SIGNUP_USER: "@@auth/INVITATION_SIGNUP_USER",
  SET_NEW_PASSWORD: "@@auth/SET_NEW_PASSWORD",
  FORGOT_PASSWORD: "@@auth/FORGOT_PASSWORD",
  FORGOT_PASSWORD_CHANGE: "@@auth/FORGOT_PASSWORD_CHANGE",
  VERIFY_EMAIL: "@@auth/VERIFY_EMAIL",
  TOKEN_EXPIRATION: "@@auth/TOKEN_EXPIRATION",
  RESET: "@@auth/RESET",
};
